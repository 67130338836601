import {
    DATA_ROW_ID,
    DATA_ROW_SOURCE,
    DATA_ROW_TYPE,
} from '@innhold/core/front/constants';
import { getPulse } from '@innhold/core/tracking/pulse';
import { createViewObserver } from '@innhold/core/tracking/utils/createViewObserver';

export default async () => {
    const pulse = await getPulse();

    createViewObserver(
        `[${DATA_ROW_ID}][${DATA_ROW_SOURCE}][${DATA_ROW_TYPE}]`,
        (target) => {
            const row = target.getAttribute(DATA_ROW_ID);
            const source = target.getAttribute(DATA_ROW_SOURCE);
            const contentType = target.getAttribute(DATA_ROW_TYPE);

            pulse.trackElementView({
                object: {
                    id: `row-${row}`,
                    name: `Row ${row}`,
                    type: 'Row',
                    custom: {
                        row: row ? Number(row) : null,
                        source,
                        contentType,
                    },
                },
            });
        },
        { rootMargin: '0px' }
    );
};
